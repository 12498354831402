<template>
  <ActionWrapper>
    <a-row>
      <a-col :xs="24">
        <a-steps :current="current">
          <a-step
            v-for="item in steps"
            :status="status"
            :key="item.title"
            :title="item.title"
          />
        </a-steps>
        <div class="steps-content onboarding-container" v-if="current === 0">
          <a-form name="domain-configuration" layout="vertical">
            <a-form-item name="domain" label="Website domain *">
              <a-input
                type="text"
                name="domain"
                id="domain"
                style="width: 500px;"
                v-model:value="domain"
                placeholder="E.G https://yourshop@domain.com"
              />
              <span class="error-messages" v-if="v$.domain.$error">
                {{ v$.domain.$errors[0].$message }}
              </span>
            </a-form-item>
            <a-button
                class="btn-save"
                type="primary"
                style="height: 44px; width: 100px"
                @click="saveDomain"
              >
                {{ store.state.actions.isLoading ? "Loading..." : "Save" }}
            </a-button>
          </a-form>
        </div>
        <br>
        <span style="font-size: 15px;">Insert this code snippet in your website header</span>
        <div class="script-configuration" v-if="current === 0">
          <code class="language-markup">
            <pre class="code-snippet-custom">
              {{ installationScript }}
            </pre>
          </code>
        </div>
        <div class="steps-content onboarding-container" v-if="current === 1">
          <BasicFormWrapper class="mb-25">
            <a-form name="popup-configuration" layout="vertical">
              <a-row :gutter="30">
                <a-col :sm="12" :xs="24" class="mb-25">
                  <a-form-item label="Select popup color *" name="popup-color">
                    <ColorPicker
                      default-format="rgb"
                      :color="popupData.color"
                      @color-change="onMainColorUpdate"
                    />
                    <span
                      class="error-messages"
                      v-if="v$.popupData.color.$error"
                    >
                      {{ v$.popupData.color.$errors[0].$message }}
                    </span>
                  </a-form-item>
                </a-col>
                <a-col :sm="12" :xs="24" class="mb-25">
                  <a-form-item
                    label="Select popup border color"
                    name="popup-border-color"
                  >
                    <ColorPicker
                      default-format="rgb"
                      :color="popupData.secondary_color"
                      @color-change="onSecondaryColorUpdate"
                    />
                  </a-form-item>
                </a-col>
                <a-col :sm="12" :xs="24" class="mb-25">
                  <a-form-item name="header_message" label="Header message *">
                    <a-input
                      type="text"
                      name="header_message"
                      v-model:value="popupData.header_message"
                    />
                    <span
                      class="error-messages"
                      v-if="v$.popupData.header_message.$error"
                    >
                      {{ v$.popupData.header_message.$errors[0].$message }}
                    </span>
                  </a-form-item>
                  <a-form-item name="loading_message" label="Loading message *">
                    <a-input
                      type="text"
                      name="loading_message"
                      v-model:value="popupData.loading_message"
                    />
                    <span
                      class="error-messages"
                      v-if="v$.popupData.loading_message.$error"
                    >
                      {{ v$.popupData.loading_message.$errors[0].$message }}
                    </span>
                  </a-form-item>

                  <a-form-item
                    name="buy_now_button_message"
                    label="Buy now button message"
                  >
                    <a-input
                      type="text"
                      name="buy_now_button_message"
                      v-model:value="popupData.buy_now_button_message"
                    />
                  </a-form-item>
                </a-col>

                <a-col :sm="12" :xs="24" class="mb-25">
                  <a-form-item
                    name="like_button_message"
                    label="Like button message"
                  >
                    <a-input
                      type="text"
                      name="like_button_message"
                      v-model:value="popupData.like_button_message"
                    />
                  </a-form-item>

                  <a-form-item
                    name="dislike_button_message"
                    label="Dislike button message"
                  >
                    <a-input
                      type="text"
                      name="dislike_button_message"
                      v-model:value="popupData.dislike_button_message"
                    />
                  </a-form-item>

                  <a-form-item name="in_stock_message" label="In stock message">
                    <a-input
                      type="text"
                      name="in_stock_message"
                      v-model:value="popupData.in_stock_message"
                    />
                  </a-form-item>
                </a-col>

                <a-col :sm="12" :xs="24" class="mb-25">
                  <a-form-item
                    name="link_copied_message"
                    label="Link copied message"
                  >
                    <a-input
                      type="text"
                      name="link_copied_message"
                      v-model:value="popupData.link_copied_message"
                    />
                  </a-form-item>
                </a-col>

                <a-col :sm="12" :xs="24" class="mb-25">
                  <a-form-item
                    name="come_back_button_message"
                    label="Come back button message"
                  >
                    <a-input
                      type="text"
                      name="come_back_button_message"
                      v-model:value="popupData.come_back_button_message"
                    />
                  </a-form-item>
                </a-col>

                <a-col :sm="12" :xs="24" class="mb-25">
                  <a-form-item
                    name="position"
                    class="checkbox-position"
                    label="Position *"
                    style="flex-direction: inherit !important;"
                  >
                    <a-radio-group v-model:value="popupData.position">
                      <a-radio value="left">Left</a-radio>
                      <a-radio value="right">Right</a-radio>
                    </a-radio-group>
                    <br />
                    <span
                      class="error-messages"
                      v-if="v$.popupData.position.$error"
                    >
                      {{ v$.popupData.position.$errors[0].$message }}
                    </span>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-button
                class="btn-save"
                type="primary"
                style="height: 44px; width: 100px"
                v-if="current === 1"
                @click="savePopupData"
              >
                {{ store.state.actions.isLoading ? "Loading..." : "Save" }}
              </a-button>
            </a-form>
          </BasicFormWrapper>
        </div>
        <div class="steps-content onboarding-container" v-if="current === 2">
          <a-form name="popup-configuration" layout="vertical">
            <a-form-item
              label="Feed location (Feed Location. E.G https://yourshop@domain.com/products_feed.csv) *"
              name="feed_location"
            >
              <a-input
                id="feed-location"
                name="feed_location"
                v-model:value="feedData.feed_location"
                placeholder="Feed Location. E.G https://yourshop@domain.com/products_feed.csv"
              />
              <br />
              <span
                class="error-messages"
                v-if="v$.feedData.feed_location.$error"
              >
                {{ v$.feedData.feed_location.$errors[0].$message }}
              </span>
              <br />
              <a-button
                class="btn-save"
                type="primary"
                v-if="current === 2"
                @click="importProductsWithCustomFeed"
              >
                {{ store.state.actions.isLoading ? "Loading..." : "Save" }}
              </a-button> 
            </a-form-item>
            <span>If you have already imported from a plugin (Shopify, Woocommerce, Magento), please press the button below to check.</span>
            <br>
            <br>
            <a-button
                class="btn-save"
                type="primary"
                v-if="current === 2"
                @click="verifyProducts"
              >
                {{ store.state.actions.isLoading ? "Loading..." : "Verify" }}
              </a-button> 
          </a-form>
        </div>
        <div class="steps-action">
          <a-button
            class="btn-next"
            type="primary"
            @click="next"
            v-if="store.state.auth.step > 0 && current < 2"
          >
            Next <sdFeatherIcons type="arrow-right" size="16" />
          </a-button>
          <a-button
            class="btn-prev"
            v-if="store.state.auth.step > 0 && current > 0"
            @click="prev"
          >
            <sdFeatherIcons type="arrow-left" size="14" />
            Previous
          </a-button>
        </div>
      </a-col>
    </a-row>
  </ActionWrapper>
</template>
  
<script>
import { ActionWrapper } from './style';
import { BasicFormWrapper } from '../styled';
import useVuelidate from "@vuelidate/core";
import ProductService from '@/services/ProductService';
import PopupService from '@/services/PopupService';
import FeedService from '@/services/FeedService';

import DomainAuthorizationService from '@/services/DomainAuthorizationService';

import { required, helpers } from "@vuelidate/validators";
import store from '@/vuex/store';

import { ColorPicker } from 'vue-accessible-color-picker'

export default {
  name: "Basic",

  components: { ActionWrapper, ColorPicker, BasicFormWrapper },

  data() {
    return {
      v$: useVuelidate(),
      steps: [{"title": "Step 1 - Verify domain & Script configuration"}, {"title": "Step 2 - Popup configuration"}, {"title": "Step 3 - Feed import"}],
      current: 0,
      feedData: {
        feed_location: ''
      },
      popupData: {
        color: "",
        position: "",
        header_message: "",
        loading_message: "",
        buy_now_button_message: "",
        come_back_button_message: "",
        dislike_button_message: "",
        in_stock_message: "",
        like_button_message: "",
        link_copied_message: "",
        secondary_color: ""
      },
      domain: '',
      store: store,
      installationScript: "",
      status: ""
    }
  },

  mounted() {
    this.$nextTick(async () => {
      if (store.state.auth.step === 3) this.status = "finish";
      await this.getFeedData();
      await this.getPopupData();
      await this.getDomain();
    });
    this.setInstaller();
  },

  methods: {
    async getFeedData() {
      const response = (await FeedService.getFeed()).data;

      this.feedData.feed_location = response.feed?.feed_url;
    },

    async getPopupData() {
      const response = (await PopupService.getPopup()).data;

      this.popupData.color = response.popup.color;
      this.popupData.secondary_color = response.popup?.secondary_color;
      this.popupData.position = response.popup.position;
      this.popupData.header_message = response.popup.header_message;
      this.popupData.loading_message = response.popup.loading_message;
      this.popupData.link_copied_message = response.popup.link_copied_message;
      this.popupData.come_back_button_message = response.popup.come_back_button_message;
      this.popupData.like_button_message = response.popup.like_button_message;
      this.popupData.dislike_button_message = response.popup.dislike_button_message;
      this.popupData.in_stock_message = response.popup.in_stock_message;
      this.popupData.buy_now_button_message = response.popup.buy_now_button_message;
    },

    async getDomain() {
      const response = (await DomainAuthorizationService.getDomain()).data;

      this.domain = response.domain.domain;
    },

    onMainColorUpdate (eventData) {
      this.popupData.color = eventData.cssColor
    },

    onSecondaryColorUpdate (eventData) {
      this.popupData.secondary_color = eventData.cssColor;
    },

    setInstaller() {
      const scriptTag = 'script'
      this.installationScript =`
      <${scriptTag} src="https://trackui.smartbusiness.digital/load.min.js"></${scriptTag}>
      <${scriptTag}>
        window.TrackUI.record({
          clientId: "${store.state.auth.userDetails.client_id}",
        });
      </${scriptTag}>`;
    },

    async importProductsWithCustomFeed() {
      // if (this.store.state.auth.userDetails.email === 'demo@smartbusiness.digital') {
      //   await window.showAlert("warning", "Warning", 'The action is not available on a demo account, try it on an account with real data!');
      //   return;
      // }

      this.$store.dispatch("actions/setLoading", true);
      this.v$.feedData.$touch();
      if (this.v$.feedData.$pending || this.v$.feedData.$error) return;

      window.showAlert("success", "Success", "The products are loaded. Please wait.");

      const response = await ProductService.importProductsWithCustomFeed(this.feedData);
      console.log(response);

      if (response.status === 200) {
        window.showAlert("success", "Success", "The products have been imported. ✅");

        this.$store.dispatch("auth/setUserStep", 3);

        this.current = 2;

        this.$store.dispatch("actions/setLoading", false);
      }
    },

    async savePopupData() {
      if (this.store.state.auth.userDetails.email === 'demo@smartbusiness.digital') {
        await window.showAlert("warning", "Warning", 'The action is not available on a demo account, try it on an account with real data!');
        return;
      }

      this.$store.dispatch("actions/setLoading", true);
      this.v$.popupData.$touch();
      if (this.v$.popupData.$pending || this.v$.popupData.$error) {
        this.$store.dispatch("actions/setLoading", false);
        return;
      }
      
      await PopupService.savePopupData(this.popupData);

      window.showAlert("success", "Success", "The data has been saved.");

      this.$store.dispatch("auth/setUserStep", 2);

      this.current = 2;

      this.$store.dispatch("actions/setLoading", false);
    },

    async saveDomain() {
      if (this.store.state.auth.userDetails.email === 'demo@smartbusiness.digital') {
        await window.showAlert("warning", "Warning", 'The action is not available on a demo account, try it on an account with real data!');
        return;
      }

      this.$store.dispatch("actions/setLoading", true);
      this.v$.domain.$touch();

      if (this.v$.domain.$pending || this.v$.domain.$error) {
        this.$store.dispatch("actions/setLoading", false);
        return;
      }

      await DomainAuthorizationService.saveDomain({domain: this.domain});

      this.getDomain();

      window.showAlert("success", "Success", "The data has been saved.");

      this.$store.dispatch("auth/setUserStep", 1);

      this.current = 1;

      this.$store.dispatch("actions/setLoading", false);
    },

    async verifyProducts() {
      this.$store.dispatch("actions/setLoading", true);

      const response = await ProductService.verifyProducts();

      if (response.data.success && response.data.success === true) {
        await window.showAlert("success", "Success", "The products have been checked. The account is also verified and can be used.");

        this.current = 2;
      }

      this.$store.dispatch("actions/setLoading", false);
    },

    next() {
      if (this.current === 2) return;
      this.current === 1 && store.state.auth.step <= 1 
      || this.current === 2 && store.state.auth.step <= 2  
      ? window.showAlert("warning", "Warning", "You must complete the current step to continue!") 
      : this.current++;
    },

    prev() {
      this.current--;
    }
  },

  validations() {
    return {
      feedData: {
        feed_location: {
          required: helpers.withMessage(
            "The feed location field is required",
            required
          )
        }
      },

      domain: {
        required: helpers.withMessage(
          "Website domain field is required for script authorization",
          required
        )
      },

      popupData: {
        color: {
          required: helpers.withMessage(
            "The color field is required",
            required
          ),
        },

        position: {
          required: helpers.withMessage(
            "The position field is required",
            required
          )
        },

        header_message: {
          required: helpers.withMessage(
            "The header message field is required",
            required
          )
        },

        loading_message: {
          required: helpers.withMessage(
            "The loading message field is required",
            required
          )
        }
      }
    }
  }
}
</script>
  
<style scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.steps-action {
  margin-top: 24px;
}

#feed-location {
  width: 500px;
}

#domain {
  width: 600px !important;
}

@media only screen and (max-width: 600px) {
  #feed-location {
    width: 280px;
  }

  #domain {
    width: 380px;
  }
}
.ant-col {
  flex-direction: initial !important;
}

.onboarding-container {
  display: flex;
  justify-content: center;
}

.script-configuration {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
}
</style>
  
