<template>
  <sdPageHeader title="Onboarding" />
  <Main>
    <a-row :gutter="25">
      <a-col :xs="24">
        <sdCards headless>
            <sdCards>
              <StepsOnboarding step="switch" size="small" :data="steps" />
            </sdCards>
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>
  
<script>
import { Main } from '../styled';
import { data, steps, dataDescription } from '../../demoData/step-data.json';
import StepsOnboarding from './StepsOnboarding.vue';

export default {
  name: 'Onboarding',
  components: {
    Main,
    StepsOnboarding
  },

  data() {
    return {
      data: data,
      steps: steps,
      dataDescription: dataDescription
    }
  }
}
</script>
  